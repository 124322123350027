import Button, { BlueButton } from "@/components/fields/button";
import LabelInput from "@/components/fields/input";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { signIn } from "next-auth/react";

import styles from "styles/login.module.css";
import miscStyles from "styles/misc.module.css";
import IsLoggedOutWrapper from "@/components/IsLoggedOutWrapper";
import clsx from "clsx";
import CryptoFooter from "@/components/Footer/CryptoFooter";
import { StoreContext } from "@/context";
import { getAuthCode, validateRecaptchaToken } from "@/services/auth";
import Head from "next/head";
import Script from "next/script";
import { LEAGUES } from "@/utils/constants";

export default function Login() {
  const router = useRouter();
  const context = useContext(StoreContext);
  const authAction = context.actions.auth;
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false);

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      if (typeof grecaptcha !== 'undefined') {
        grecaptcha.enterprise.ready(async () => {
          const token = await grecaptcha.enterprise.execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_TOKEN, {action: 'LOGIN'});
          const validation = await validateRecaptchaToken(token, 'login')
          if (validation.tokenProperties.valid) {
            context.actions.setAppLeague(LEAGUES[0]) // Reset Starting league (NBA)
            let res = await signIn(
              // "cognito",
              "credentials",
              {
                email: email,
                password,
                callbackUrl: `${window.location.origin}/home`,
                redirect: false,
              } /*  */
            );
            if (res.error) {
              if (res.error === "User is not confirmed.") {
                authAction.setEmail(email);
                authAction.setPassword(password);
                router.push(`/signup/confirm/${email}?cs=true`);
              } else {
                console.log("rwa", res);
                setError(res.error);
              }
            }
          } else {
            setError('We\'re sorry, but we suspect that this traffic is automated. Please try again later.')
          }
          setLoading(false);
        });
      }
    } catch (err) {
      console.error({ err })
      setLoading(false);
      setError("Something went wrong. Please try again.");
    }
  };

  useEffect(() => {
    if (!document.querySelector(`[src="https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_TOKEN}"]`)) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITE_TOKEN}`;
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setIsCaptchaLoaded(true);
      };
      document.body.appendChild(script);
    } else {
      setIsCaptchaLoaded(true);
    }
  }, []);

  return (
    <IsLoggedOutWrapper>
      <Head>
        <title>CryptoSports | Login</title>
        <Script src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_CONFIG_ANALYTICS}`} />
        <Script id="google-analytics">
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.NEXT_PUBLIC_CONFIG_ANALYTICS}');
          `}
        </Script>
      </Head>
      <form onSubmit={onSubmit} id="loginForm">
        <div className={styles.loginContent}>
          <div className={styles.titleGroup}>
            <img
              src="/images/cslogo.svg"
              alt="CryptoSports"
              className={styles.logoSvg}
            />
            <h4 className={miscStyles.tagline}>Collect. Play. Win.</h4>
          </div>

          <div className={styles.formFields}>
            <LabelInput
              label="Email"
              placeholder="Enter Email"
              value={email}
              onChange={setEmail}
              inputOptions={{
                className: styles.input,
              }}
              disabled={loading}
            />
            <LabelInput
              label="Password"
              type="password"
              placeholder="Enter Password"
              value={password}
              onChange={setPassword}
              inputOptions={{
                className: styles.input,
              }}
              disabled={loading}
            />
          </div>

          <div className={styles.buttonContainer}>
            {error && <p className={styles.error}>{error}</p>}
            <BlueButton
              text={loading ? 'Logging in...' : 'Login'}
              disabled={!email || !password || !isCaptchaLoaded || loading}
              onClick={onSubmit}
              className={styles.loginButton}
              type="submit"
            />
            {/* <BlueButton
              className={clsx(styles.loginButton, styles.googleSignupButton)}
              onClick={() => {
                signIn("cognito");
              }}
              text="Sign in with Google"
              iconSrc="/images/icons/google.png"
            /> */}
          </div>
          <div className={styles.instructionContainer}>
            <p className={styles.instruction}>
              {"Don't have an account? "}
              <Link href={"/signup"}>
                <span className={styles.link}>Sign up</span>
              </Link>
            </p>
            <p className={styles.instruction}>
              <Link href={"/reset-password"}>
                {/* <span className={styles.link}> */}
                <b className={styles.reset}>Forgot password?</b>
                {/* </span> */}
              </Link>
            </p>
          </div>
        </div>
      </form>

      <CryptoFooter />
    </IsLoggedOutWrapper>
  );
}
